<template>
  <page-view>
    <div v-if="agent.show" class="agent_show">
      <div class="base_setting">
        <div class="setting_title">基本设置</div>
        <a-form :hideRequiredMark="true">
          <a-form-item
            label="自定义域名"
            :labelCol="{ lg: { span: 2 }, sm: { span: 24 }, md: { span: 24 } }"
            :wrapperCol="{ lg: { span: 8 }, sm: { span: 8 } }"
          >
            <a-input
              :readonly="true"
              class="domain_name"
              placeholder="二级域名，建议3-6位字母"
              v-model="edit.host"
              addon-before="http://"
              :addon-after="baseUrl"
            />
            <span
              @click="copyHost"
              style="color:#1890ff;margin-left:8px;cursor: pointer;"
            >点击复制</span
            >
          </a-form-item>
          <a-form-item
            label="公司名称"
            :labelCol="{ lg: { span: 2 }, sm: { span: 24 }, md: { span: 24 } }"
            :wrapperCol="{ lg: { span: 8 }, sm: { span: 8 } }"
          >
            <a-input
              class="input_width"
              placeholder="请输入公司名称"
              v-model="edit.companyName"
            />
          </a-form-item>
          <a-form-item
            label="网站名称"
            :labelCol="{ lg: { span: 2 }, sm: { span: 24 }, md: { span: 24 } }"
            :wrapperCol="{ lg: { span: 8 }, sm: { span: 8 } }"
          >
            <a-input
              class="input_width"
              placeholder="请输入网站名称"
              v-model="edit.systemTitle"
            />
          </a-form-item>
          <a-form-item
            label="网站logo："
            :labelCol="{ lg: { span: 2 }, sm: { span: 24 }, md: { span: 24 } }"
            :wrapperCol="{ lg: { span: 8 }, sm: { span: 8 } }"
          >
            <a-upload
              :multiple="false"
              :showUploadList="false"
              list-type="picture"
              :customRequest="customRequest"
              accept="image/gif, image/png, image/jpeg, image/webp"
            >
              <img
                v-if="preview.image"
                :src="preview.image"
                class="priview_image"
              />
              <div v-else class="picture_upload">
                <a-icon type="plus" />
                <div class="ant-upload-text">上传图片</div>
              </div>
              <div
                style="color:#1890ff;text-align:center;cursor: pointer;margin-top:8px"
              >
                点击修改
              </div>
            </a-upload>
          </a-form-item>
        </a-form>
      </div>
      <div class="commission_setting">
        <div class="setting_title">
          提成设置
          <div class="extension_desc">
            * 推广下级开通分站，可得
            <span class="extension_money">￥{{ agentLevel.inviter }}元</span
            >佣金，并且可享受该分站销售订单的永久提成
          </div>
        </div>
        <a-form :hideRequiredMark="true">
          <a-form-item
            label="卖出加价"
            :labelCol="{ lg: { span: 2 }, sm: { span: 24 }, md: { span: 24 } }"
            :wrapperCol="{ lg: { span: 8 }, sm: { span: 8 } }"
          >
            <a-input-number
              :min="0"
              :precision="2"
              class="input_width"
              placeholder="卖出加价"
              v-model="edit.sell"
            />
            <a-tooltip placement="right">
              <template slot="title">
                <span
                >在你成本的基础上加价，例如：成本2元/单，加价0.3，那么售价就是2.3元/单</span
                >
              </template>
              <a-icon type="question-circle" class="icon" />
            </a-tooltip>
          </a-form-item>

          <a-form-item
            label="下级分站加价"
            :labelCol="{ lg: { span: 2 }, sm: { span: 24 }, md: { span: 24 } }"
            :wrapperCol="{ lg: { span: 8 }, sm: { span: 8 } }"
          >
            <a-input-number
              :min="0"
              :precision="2"
              class="input_width"
              placeholder="请输入"
              v-model="edit.part"
            />
            <a-tooltip placement="right">
              <template slot="title">
                <span
                >在你分站的基础上，给推广的分站设置成本价,例如：你是2元/单，加价0.3，这个分站每卖出1单，你就有0.3元的佣金</span
                >
              </template>
              <a-icon type="question-circle" class="icon" />
            </a-tooltip>
          </a-form-item>

          <a-form-item
            label="用户推广提成"
            :labelCol="{ lg: { span: 2 }, sm: { span: 24 }, md: { span: 24 } }"
            :wrapperCol="{ lg: { span: 8 }, sm: { span: 8 } }"
          >
            <a-input-number
              :min="0"
              :precision="2"
              class="input_width"
              placeholder="请输入"
              v-model="edit.inviter"
            />
            <a-tooltip placement="right">
              <template slot="title">
                <span
                >B用户通过A用户的推广链接下单，A用户可以拿到你设置的推广提成，这个提成佣金从你的利润里扣除</span
                >
              </template>
              <a-icon type="question-circle" class="icon" />
            </a-tooltip>
          </a-form-item>

          <a-form-item
            label="商品加价"
            :labelCol="{ lg: { span: 2 }, sm: { span: 24 }, md: { span: 24 } }"
            :wrapperCol="{ lg: { span: 8 }, sm: { span: 8 } }"
          >
            <a-input-number
              :min="0"
              :precision="2"
              class="input_width"
              placeholder="请输入"
              v-model="edit.goodsPart"
            />
          </a-form-item>
        </a-form>
      </div>
      <div class="service_setting">
        <div class="setting_title">客服设置</div>
        <a-form :hideRequiredMark="true">
          <a-form-item
            label="QQ客服"
            :labelCol="{ lg: { span: 2 }, sm: { span: 24 }, md: { span: 24 } }"
            :wrapperCol="{ lg: { span: 8 }, sm: { span: 8 } }"
          >
            <a-input
              class="input_width"
              placeholder="请输入QQ号码"
              v-model="edit.qq"
            />
          </a-form-item>
          <a-form-item
            label="微信客服"
            :labelCol="{ lg: { span: 2 }, sm: { span: 24 }, md: { span: 24 } }"
            :wrapperCol="{ lg: { span: 8 }, sm: { span: 8 } }"
          >
            <a-upload
              :multiple="false"
              :showUploadList="false"
              listType="picture"
              :customRequest="customRequestWechat"
              accept="image/gif, image/png, image/jpeg, image/webp"
            >
              <img
                v-if="preview.wechat"
                :src="preview.wechat"
                class="priview_image"
              />
              <div v-else class="picture_upload">
                <a-icon type="plus" />
                <div class="ant-upload-text">上传图片</div>
              </div>
              <div
                style="color:#1890ff;text-align:center;cursor: pointer;margin-top:8px"
              >
                点击修改
              </div>
            </a-upload>
          </a-form-item>
        </a-form>
      </div>

      <a-button
        @click="update()"
        type="primary"
        class="setting_btn"
      >保存设置</a-button
      >
    </div>
    <div v-else class="agent_noshow">
      <div class="agent_no">
        <div class="agent_title">分站加盟，即刻拥有属于自己的网站~</div>
        <div class="agent_subtitle">
          网站完全独立控制，管理操作简单；全天24小时自动售单，无需人工干预。
        </div>
        <div class="agent_advantage">
          <div class="agent_advantage_title">加盟优势：</div>
          <div class="agent_all_item">
            <div class="item_one">
              <div class="img img1"></div>
              <span>提供技术支持，24小时技术在线维护</span>
            </div>
            <div class="item_one">
              <div class="img img2"></div>
              <span>建站快，10分钟即可实现建站、上手运营网站</span>
            </div>
            <div class="item_one">
              <div class="img img3"></div>
              <span>利润直达账户，提现24小时内到账</span>
            </div>
            <div class="item_one">
              <div class="img img4"></div>
              <span>独立域名，独立后台自由管理</span>
            </div>
            <div class="item_one">
              <div class="img img5"></div>
              <span>免费提供网站API接口，无需任何费用</span>
            </div>
            <div class="item_one">
              <div class="img img6"></div>
              <span>前景可观，客源稳定，收入稳定</span>
            </div>
            <div class="item_one">
              <div class="img img7"></div>
              <span>分站成本低，提供服务器、域名维护、升级服务</span>
            </div>
            <div class="item_one">
              <div class="img img8"></div>
              <span>提供稳定、安全、低价的快递费+礼品货源</span>
            </div>
          </div>
        </div>
        <div class="agent_step_title">开通分站流程：</div>
        <div class="agent_step">
          <!-- <a-row :gutter="48">
          <a-col :lg="4" :sm="24">-->
          <div class="step_item">
            <div class="item_left">
              <div class="item_title">①注册账号</div>
              <div class="item_desc">
                注册会员账号，若已有账号，直接在首页进行登录
              </div>
            </div>
            <div class="item_right"></div>
          </div>
          <!-- </a-col>
          <a-col :lg="4" :sm="24">-->
          <div class="step_item">
            <div class="item_left">
              <div class="item_title col">②申请代理</div>
              <div class="item_desc">
                进入礼品商城，在“网站管理”点击“开通”申请分站
              </div>
            </div>
            <div class="item_right"></div>
          </div>
          <!-- </a-col>
          <a-col :lg="4" :sm="24">-->
          <div class="step_item">
            <div class="item_left">
              <div class="item_title">③等待审核</div>
              <div class="item_desc">按照操作流程，点击付费</div>
            </div>
            <div class="item_right"></div>
          </div>
          <!-- </a-col>
          <a-col :lg="4" :sm="24">-->
          <div class="step_item">
            <div class="item_left">
              <div class="item_title col">④开通成功</div>
              <div class="item_desc">对已上线的分站进行运营管理、推广管理</div>
            </div>
            <div class="item_right"></div>
          </div>
          <!-- </a-col>
          <a-col :lg="4" :sm="24">-->
          <div class="step_item">
            <div class="item_left">
              <div class="item_title">⑤网站配制</div>
              <div class="item_desc">
                系统审核通过后，分站即开通成功，可立即使用
              </div>
            </div>
            <div class="item_right"></div>
          </div>
          <!-- </a-col>
          <a-col :lg="4" :sm="24">-->
          <div class="step_item">
            <div class="item_left">
              <div class="item_title col">⑥推广盈利</div>
              <div class="item_desc">
                设置网站域名、网站名称、推广佣金，QQ号等信息，等待系统审核
              </div>
            </div>
          </div>
          <!-- </a-col>
          </a-row>-->
        </div>

        <a-button
          class="step_button"
          type="primary"
          @click="agentGet(agentLevel.id)"
        >立即开通分站（仅需￥{{ agentLevel.price }}元）</a-button
        >
        <div class="agent_desc">
          现在开通，马上拥有属于您的独立域名网站、全天24小时自动售单
        </div>
      </div>
    </div>
    <PayWay ref="payWay" @closeModal="closeModal" @showDetail="showDetail" />
  </page-view>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  oemInsert,
  oemUpdate,
  filePreUpload,
  uploadOSS,
  oemGet,
  agentLevelAll,
  agentOrderGet,
  agentOrderPay
} from '@api'
import { formatDate, uuid } from '@/utils/util'
import { PageView } from '@/layouts'
import PayWay from './pay'
import LogoVue from '@/components/tools/Logo.vue'
export default {
  components: {
    PageView,
    PayWay
  },
  data () {
    return {
      baseUrl: '',
      preview: { wechat: '', image: '', show: false },
      edit: {}, // 站点配置
      agent: { show: false, order: {} }, // 是否展示站点配置 以及 代理订单信息
      agentLevel: {} // 开通代理
    }
  },
  created () {
    agentLevelAll().then(result => {
      if (result.success) {
        this.agentLevel = result.data[0]
      } else {
        this.$message.error(result.msg)
      }
    })
    this.oemInfoGet()
  },
  methods: {
    // 复制域名
    copyHost () {
      this.copyInfo('http://' + this.edit.host + this.baseUrl)
    },
    // 获取分站信息
    oemInfoGet () {
      oemGet().then(res => {
        if (res.success) {
          this.edit = res.data
          this.baseUrl =
            '.' + res.data.host.replace(res.data.host.split('.')[0] + '.', '')
          console.log('baseUrl', this.baseUrl)
          this.edit.host = res.data.host.replace(this.baseUrl, '')
          this.agent.show = true
          this.preview.image = res.data.logo
          this.preview.wechat = res.data.wechat
        } else {
          // 没有站点权限
          this.agent.show = false
        }
      })
    },
    // 开通分站
    agentGet (agentLevelId) {
      if (!agentLevelId) {
        return this.$message.error('系统错误')
      }
      agentOrderGet({ agentLevelId: agentLevelId }).then(res => {
        if (res.success) {
          this.$refs.payWay.showModal(res.data, '开通分站')
          // this.pay(res.data.id);
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 查看详情
    showDetail () {
      this.$router.push('/account/balance')
    },
    // 支付完成后关闭页面
    closeModal () {
      // 获取分站信息
      this.oemInfoGet()
    },
    update () {
      const edit = JSON.parse(JSON.stringify(this.edit))
      if (!/^[0-9a-zA-Z]*$/.test(edit.host)) {
        return this.$message.error('域名格式错误,请输入数字或字母')
      }
      // if (edit.sell <= 0) {
      //   return this.$message.error('卖出加价必须大于0');
      // }
      if (edit.sell < edit.inviter) {
        return this.$message.error('卖出价需要大于用户推广提成')
      }
      edit.type = 1
      edit.loginTitle = edit.systemTitle
      edit.siteTitle = edit.systemTitle
      edit.logo = this.preview.image
      edit.wechat = this.preview.wechat
      // 本地强制修改为localhost:8000
      if (edit.host == 'localhost:8000') {
        edit.host = 'localhost:8000'
      } else {
        edit.host = edit.host + this.baseUrl
      }
      oemUpdate(edit).then(res => {
        if (res.success) {
          this.$message.success('成功')
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    pay (id) {
      const that = this
      this.$confirm({
        title: '支付?',
        content: '请选择余额支付或支付宝支付',
        cancelText: '余额',
        okText: '支付宝',
        onOk () {
          // 支付宝
          agentOrderPay({ id: id, payway: 'ALIPAY_QRCODE' }).then(result => {
            if (result.success) {
              console.log(result.data)
              // const opener = window.open();
              // // start alipayForm
              // window.localStorage.setItem("alipay", result.data);
              // opener.location.href = "/alipay.html";
              // // end alipayForm
              // Modal.confirm({
              //   title: "信息",
              //   content: "支付中...",
              //   onOk() {
              //     opener.close();
              //     location.reload();
              //   },
              //   onCancel() {
              //     opener.close();
              //     that.$message.error("取消支付");
              //   },
              //   okText: "完成支付",
              //   cancelText: "遇到问题",
              // });
            } else {
              that.$message.error(result.msg)
            }
          })
        },
        onCancel () {
          // 余额
          agentOrderPay({ id: id, payway: 'BALANCE' }).then(res => {
            if (res.success) {
              that.$message.success('成功')
              location.reload()
            } else {
              that.$message.error(res.msg)
            }
          })
        }
      })
    },
    customRequest (fileData) {
      // TODO 1 上传到OSS
      filePreUpload()
        .then(response => {
          const data = response.data
          const url = data.url
          const prefix = data.key
          delete data.url
          delete data.key
          const array = fileData.file.name.replace(' ', '').split('.') // 把文件名数组化
          let suffix = '' // 后缀
          if (array.length > 1) {
            suffix = `.${array.pop().toLowerCase()}`
          }
          const formData = new FormData()
          Object.keys(data).forEach(it => {
            formData.append(it, data[it])
          })
          const key = `${prefix}/${uuid()}${suffix}` // 图片访问路径
          formData.append('key', key)
          formData.append('file', fileData.file)
          uploadOSS(url, formData)
            .then(response => {
              // 上传成功，设置图片访问路径
              this.preview.image = 'http://cache.wodh.cn/' + key
              this.$message.success('上传成功.')
              return fileData.onSuccess() // 上传成功了
            })
            .catch(error => {
              this.$message.error('上传异常.' + error)
              return fileData.onError() // 拒绝上传操作
            })
        })
        .catch(error => {
          this.$message.error('授权异常.' + error)
          return fileData.onError() // 拒绝上传操作
        })
    },
    customRequestWechat (fileData) {
      // TODO 1 上传到OSS
      filePreUpload()
        .then(response => {
          const data = response.data
          const url = data.url
          const prefix = data.key
          delete data.url
          delete data.key
          const array = fileData.file.name.replace(' ', '').split('.') // 把文件名数组化
          let suffix = '' // 后缀
          if (array.length > 1) {
            suffix = `.${array.pop().toLowerCase()}`
          }
          const formData = new FormData()
          Object.keys(data).forEach(it => {
            formData.append(it, data[it])
          })
          const key = `${prefix}/${uuid()}${suffix}` // 图片访问路径
          formData.append('key', key)
          formData.append('file', fileData.file)
          uploadOSS(url, formData)
            .then(response => {
              // 上传成功，设置图片访问路径
              this.preview.wechat = 'http://cache.wodh.cn/' + key
              this.$message.success('上传成功.')
              return fileData.onSuccess() // 上传成功了
            })
            .catch(error => {
              this.$message.error('上传异常.' + error)
              return fileData.onError() // 拒绝上传操作
            })
        })
        .catch(error => {
          this.$message.error('授权异常.' + error)
          return fileData.onError() // 拒绝上传操作
        })
    }
  }
}
</script>

<style lang="less" scoped>
.agent_show {
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  background-color: #fff;
  overflow: hidden;
  padding-top: 40px;
  padding-left: 64px;
  padding-bottom: 64px;

  font-family: PingFang SC;
  .base_setting {
    margin-bottom: 40px;
  }

  .setting_title {
    display: flex;
    align-items: center;

    font-size: 24px;
    color: rgba(0, 0, 0, 0.85);
    padding-bottom: 16px;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 24px;
  }
  .picture_upload {
    cursor: pointer;
    width: 104px;
    height: 104px;
    border: 1px dashed rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .ant-upload-text {
      margin-top: 12px;
      color: #1890ff;
    }
  }
  .domain_name {
    width: 424px;
  }
  .priview_image {
    width: 104px;
    height: 104px;
    cursor: pointer;
    margin-bottom: 8px;
  }
  .extension_desc {
    height: 100%;
    margin-left: 32px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
  }
  .extension_money {
    color: #1890ff;
  }
  .input_width {
    width: 240px;
    margin-right: 8px;
  }
  .icon {
    color: #1890ff;
  }
  .setting_btn {
    margin-left: 132px;
    // margin-bottom: 64px;
  }
}
.agent_noshow {
  padding-top: 44px;
  padding-left: 56px;

  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  background-color: #fff;
  background-image: url("~@/assets/agent_bg.png");
  background-position: left bottom;
  background-repeat: no-repeat;
  font-family: PingFang SC;
  .agent_no {
    padding-bottom: 68px;
    .agent_title {
      font-size: 20px;
      margin-bottom: 16px;
      color: rgba(0, 0, 0, 0.85);
    }

    .agent_subtitle {
      margin-bottom: 56px;
      color: #1890ff;
    }

    .agent_advantage {
      margin-bottom: 32px;
      .agent_advantage_title {
        margin-bottom: 24px;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.85);
      }

      .agent_all_item {
        display: flex;
        flex-wrap: wrap;

        .item_one {
          margin-bottom: 24px;
          width: 332px;
          margin-right: 64px;
          display: flex;
          align-items: center;
          span {
            color: rgba(0, 0, 0, 0.65);
            font-size: 14px;
            margin-left: 8px;
          }
        }
        .img {
          width: 24px;
          height: 24px;
        }
        .img1 {
          background: url("~@/assets/css_sprites.png") -590px -478px;
        }
        .img2 {
          background: url("~@/assets/css_sprites.png") -590px -522px;
        }
        .img3 {
          background: url("~@/assets/css_sprites.png") -430px -466px;
        }
        .img4 {
          background: url("~@/assets/css_sprites.png") -474px -466px;
        }
        .img5 {
          background: url("~@/assets/css_sprites.png") -518px -466px;
        }
        .img6 {
          background: url("~@/assets/css_sprites.png") -10px -570px;
        }
        .img7 {
          background: url("~@/assets/css_sprites.png") -54px -570px;
        }
        .img8 {
          background: url("~@/assets/css_sprites.png") -98px -570px;
        }
      }
    }

    .agent_step_title {
      color: rgba(0, 0, 0, 0.85);
      font-size: 20px;
      margin-bottom: 24px;
    }

    .agent_step {
      width: 1224px;
      border-radius: 4px;
      border: 1px dashed #1890ff;
      display: flex;
      margin-bottom: 24px;
      padding: 32px 32px 0;
      .step_item {
        display: flex;
        margin-bottom: 32px;
        margin-right: 22px;

        .item_left {
          width: 140px;
          margin-right: 22px;
          .item_title {
            margin-bottom: 16px;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
          }
          .col {
            color: #1890ff;
          }
          .item_desc {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.45);
          }
        }
        .item_right {
          margin-top: 50px;
          width: 20px;
          height: 8px;
          background: url("~@/assets/css_sprites.png") -539px -570px;
        }
      }
    }

    .step_button {
      margin-bottom: 16px;
      width: 268px;
      height: 56px;
    }

    .agent_desc {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
    }
  }
}
</style>
