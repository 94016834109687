<template>
  <a-modal v-model="visible" title="付款" :footer="false" width="720px" @cancel="cancelModal">
    <div class="container">
      <div class="hasno_pay" v-if="!haspay">
        <div class="good_name">
          <p>
            商品名称：
            <span>{{ goodName }}</span>
          </p>
        </div>
        <div class="money">
          <p>
            支付金额：
            <span>￥{{ parseFloat(orderData.amount).toFixed(2) }}</span>
          </p>
        </div>
        <div class="payway_wrapper">
          <div class="payway_btn">
            <span>支付方式：</span>
            <div class="way">
              <div
                class="payway"
                :class="payWay == 'BALANCE' ? 'active' : ''"
                @click="changePayWay('BALANCE')"
              >
                <img class="pay_img" src="@/assets/icon／balance.png" />
                <img v-if="payWay == 'BALANCE'" src="@/assets/bottomright.png" alt class="dagou" />
                <span>账户余额</span>
              </div>
              <div
                class="payway"
                :class="payWay == 'ALIPAY_QRCODE' ? 'active' : ''"
                @click="changePayWay('ALIPAY_QRCODE')"
              >
                <img class="pay_img" src="@/assets/icon／alipay.png" />
                <img
                  v-if="payWay == 'ALIPAY_QRCODE'"
                  src="@/assets/bottomright.png"
                  alt
                  class="dagou"
                />
                <span>支付宝</span>
              </div>
            </div>
          </div>
          <div class="payway_info">
            <p class="balance" v-if="payWay == 'BALANCE'">
              账户剩余：￥{{ account.balance }}元
              <span @click="() => $router.push('/account/balance')">充值</span>
            </p>
            <div class="qrcode" v-if="payWay == 'ALIPAY_QRCODE'">
              <canvas id="canvas"></canvas>
              <div class="qrcode_tip">
                打开支付宝
                <br />扫描二维码支付
              </div>
            </div>
          </div>
          <!-- <div style=" color: #f93244; margin-left: 70px;font-size:18px">注：支付宝单笔支付暂时关闭，请充值到余额支付</div> -->
          <div class="paynow_btn" @click="handleOrderPay" v-if="payWay == 'BALANCE'">立即支付</div>
        </div>
      </div>
      <div class="haspay" v-else>
        <a-icon type="check-circle" theme="filled" :style="{ color: '#52c41a', fontSize: '70px' }" />
        <div class="pay_success">支付成功</div>
        <div class="order_view">可在账户资金中查看</div>
        <div class="goback_btn">
          <div class="purchase_btn" @click="showDetail">查看详情</div>
          <div class="orderlist_btn" @click="closeModal">关闭页面</div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { agentOrderPay, accountGet, agentAlipayCheck } from "@/api";

import QRCode from "qrcode";
export default {
  data() {
    return {
      visible: false,
      orderData: [],
      payWay: "BALANCE",
      account: {},
      haspay: false,
      goodName: "",
      time: null,
    };
  },
  methods: {
    cancelModal() {
      clearInterval(this.time);
      this.haspay ? this.showDetail() : (this.visible = false);
    },
    closeModal() {
      clearInterval(this.time);
      this.visible = false;
      this.$emit("closeModal");
      this.haspay = false;
    },
    showDetail() {
      this.visible = false;
      this.$emit("showDetail");
      this.haspay = false;
    },
    checkPay() {
      const { orderData } = this;
      const paramsObj = {
        orderNo: orderData.no,
        type: "agent",
      };
      agentAlipayCheck(paramsObj, 1).then((res) => {
        console.log(res);
        if (res.success) {
          this.$message.success("支付成功");
          this.haspay = true;
          clearTimeout(this.time);
        } else {
          this.time = setTimeout(() => {
            this.checkPay(paramsObj);
          }, 2000);
        }
      });
    },
    //支付二维码
    aliPay() {
      const { payWay, orderData, account } = this;
      const params = {
        id: orderData.id,
        payway: payWay,
      };
      agentOrderPay(params).then((res) => {
        if (res.success) {
          var canvas = document.getElementById("canvas");
          QRCode.toCanvas(canvas, res.data);
          this.checkPay(this.orderData.id);
        }
      });
    },
    changePayWay(way) {
      this.payWay = way;
      if (this.payWay == "ALIPAY_QRCODE") {
        this.aliPay();
      }
    },
    showModal(data, name) {
      console.log(data);
      this.payWay = "BALANCE";
      this.goodName = name;
      this.orderData = data;
      this.visible = true;
      this.getAccount();
    },

    getAccount() {
      //查余额
      accountGet().then((res) => {
        if (res.success) {
          this.account = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleOrderPay() {
      const { payWay, orderData, account } = this;
      const params = {
        id: orderData.id,
        payway: payWay,
      };
      if (account.balance < orderData.amount) {
        this.$message.warning("当前账户余额不足");
        return;
      }
      agentOrderPay(params).then((res) => {
        if (res.success) {
          this.$message.success("支付成功");
          this.haspay = true;
        } else {
          this.$message.error(res.msg);
          this.haspay = false;
        }
      });
    },
    destroyed() {
      window.clearInterval(this.time);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  font-family: PingFang SC;
  .hasno_pay {
    padding-left: 58px;
    .good_name {
      font-size: 14px;
    }
    .money {
      span {
        color: #f93244;
        font-size: 24px;
      }

      .balance {
        span {
          color: #0098d6;
          cursor: pointer;
        }
      }
    }
    .dagou {
      position: absolute;
      right: -1px;
      bottom: -1px;
    }
    .payway_wrapper {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      .payway_btn {
        display: flex;
        align-items: center;
        span {
          height: 20px;
        }
        .way {
          display: flex;
          .payway {
            cursor: pointer;

            margin-right: 16px;
            width: 120px;
            height: 32px;
            border: 1px solid rgba(217, 217, 217, 1);
            border-radius: 4px;
            display: flex;
            align-items: center;
            .pay_img {
              margin: 0 8px;
            }
          }
          .active {
            border: 1px solid #1890ff;
            position: relative;
            // border: 0;
            // background-image: url("~@/assets/支付按钮.png");
            // background-size: 100% 100%;
          }
        }
      }
      .payway_info {
        margin-top: 16px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 12px;
        margin-left: 70px;
        span {
          color: #f93244;
          cursor: pointer;
        }
        .qrcode {
          display: flex;
          margin-bottom: 80px;
          margin-top: 8px;
          align-items: center;
          .qrcode_img {
            display: inline-block;
          }
          #canvas {
            width: 140px;
            height: 140px;
          }
          .qrcode_tip {
            margin-left: 24px;
          }
        }
      }
      .paynow_btn {
        cursor: pointer;

        margin: 43px 0 15px 70px;
        color: #fff;
        width: 88px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background-color: #f93244;
      }
    }
  }
  .haspay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 36px;
    .pay_success {
      margin-top: 29px;
      font-size: 24px;
      margin-bottom: 8px;
    }
    .order_view {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 24px;
    }
    .goback_btn {
      display: flex;
      font-size: 14px;
      margin-bottom: 32px;
      .purchase_btn {
        cursor: pointer;
        margin-right: 8px;
        width: 116px;
        height: 32px;
        color: #1e95f3;
        border: 1px solid rgba(30, 149, 243, 1);
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .orderlist_btn {
        cursor: pointer;
        width: 88px;
        height: 32px;
        background: rgba(30, 149, 243, 1);
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
      }
    }
  }
}
</style>
